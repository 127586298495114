import { useEffect } from "react";
import Table, { Pagination } from "@/presentation/components/ActionTable";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { useDetailActionUser } from "@/presentation/components/DetailContent/hooks/detailActionUser";
import { useDetailAction } from "@/main/hooks/detailAction";
import { When } from "@/presentation/components/when";

import { Loader } from "@/presentation/components/Loader";
import { Container } from "./styles";
import { ButtonResetUser } from "@/presentation/components/ButtonResetUser";
import { Text } from "alisson-application";

export function DetailActionUserTable() {
    const { setUsers, users, loading, searchUser, fetchData } =
        useDetailActionUser();
    const { user, stateHistory } = useDetailAction();

    const dados =
        users?.data?.map((user) => {
            return {
                id: user.id,
                login: user.login,
                nome: user.name,
                level: user.level,
                primeiroAcesso: user.first_access,
                dataDoResgate: user?.rewarded_itens_date,
                mobile: user.mobile,
                validator: user.validator,
                voucher: user?.rewarded_itens,
                link: user?.rewarded_itens_link,
                action: "",
            };
        }) || [];

    const columns: GridColumns = [
        {
            field: "login",
            headerName: "Login",
            width: 150,
        },
        {
            field: "nome",
            headerName: "Nome",
            width: 200,
        },
        {
            field: "level",
            headerName: "Nível",
            width: 104,
        },
        {
            field: "primeiroAcesso",
            headerName: "Primeiro acesso",
            width: 159,
        },
        {
            field: "dataDoResgate",
            headerName: "Data do resgate",
            width: 162,
            renderCell: ({ row }: GridRenderCellParams) => {
                const itens = row.dataDoResgate || [];
                return (
                    <div className="opa">
                        {itens.map((item: string) => (
                            <div key={item}>
                                <Text mb="4px">{item}</Text>
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "voucher",
            headerName: "Voucher (s) Resgatado(s)",
            width: 192,
            renderCell: ({ row }: GridRenderCellParams) => {
                const itens = row.voucher || [];
                return (
                    <div className="opa">
                        {itens.map((item: string) => (
                            <div key={item}>
                                <Text mb="4px">{item}</Text>
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "mobile",
            headerName: "Telefone",
            width: 150,
        },
        {
            field: "validator",
            headerName: "Validador",
            width: 200,
        },
        {
            field: "link",
            headerName: "Link do voucher",
            width: 200,
            renderCell: ({ row }: GridRenderCellParams) => {
                const itens = row.link || [];
                return (
                    <div className="opa">
                        {itens.map((item: string) => (
                            <div key={item}>
                                <Text mb="4px">{item}</Text>
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Ação",
            width: 337,
            renderCell: ({ row }: GridRenderCellParams) => {
                return (
                    <ButtonResetUser
                        userId={row.id}
                        actionId={stateHistory.id}
                    />
                );
            },
        },
    ];

    useEffect(() => {
        if (searchUser === null) return;
        setUsers(user.filterLoadUser(searchUser));
    }, [searchUser]);

    if (loading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    return (
        <Container data-testid="table-user">
            <Table
                rows={dados}
                columns={columns}
                nameCSV="csvName"
                header={false}
            />
            <When expr={true}>
                <Pagination
                    total={users.lastPage}
                    currentPage={async (page) => {
                        await fetchData(page);
                    }}
                    page={users.currentPage}
                />
            </When>
        </Container>
    );
}
